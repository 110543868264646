import { Subscription } from '@eagle/core-data-types';
import { Card, Divider, Stack } from '@mui/material';
import { FC } from 'react';
import { testid } from '../../util';
import { SubscriptionDetails } from './subscription-details';
import { SubscriptionUserRecipientDataType } from './subscriptions.types';

interface SubscriptionListMobileViewProps {
  disableLink?: boolean;
  displayOnCard: boolean;
  isLoading: boolean;
  subscriptions: Subscription[];
  subscriptionDetailPath: string;
  userRecipientDataType: SubscriptionUserRecipientDataType;
}

export const SubscriptionListMobileView: FC<SubscriptionListMobileViewProps> = ({ subscriptions, isLoading, displayOnCard, disableLink, subscriptionDetailPath, userRecipientDataType }) => {
  return (
    <>
      {subscriptions.map((item, i) => {
        if (!displayOnCard) {
          return (
            <Stack key={i} sx={{ filter: isLoading ? 'blur(1px)' : '', width: '100%' }}>
              {i !== 0 && <Divider sx={{ my: 2 }} />}
              <SubscriptionDetails disableLink={disableLink} displayOnCard={displayOnCard} subscription={item} subscriptionDetailPath={subscriptionDetailPath} userRecipientDataType={userRecipientDataType} data-testid={testid`subscription-list-item-${i}`} />
            </Stack >
          );
        }

        return (
          <Card key={i} sx={{ filter: isLoading ? 'blur(1px)' : '', my: 1, overflow: 'visible', width: '100%' }}>
            <SubscriptionDetails disableLink={disableLink} displayOnCard={displayOnCard} subscription={item} subscriptionDetailPath={subscriptionDetailPath} userRecipientDataType={userRecipientDataType} data-testid={testid`subscription-list-item-${i}`} />
          </Card >
        );
      })}
    </>
  );
};
