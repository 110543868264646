/* eslint-disable react-hooks/exhaustive-deps */
import { Card, CardContent, FormControl, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../auth';
import { useBoolFlag } from '../../components';
import { usePromise } from '../../hooks';
import { getEventLabelWithFlag, getFeatureLabelWithFlag, useAlertableFeatures } from '../../util';
import { FeatureAlertSettingsCardProps, Settings } from './subscription-detail.types';

const ALL = 'all';
export const FILTER_NON_ALERTABLE_DATA_FLAG = 'admin-filter-non-alertable-alert-conditions-and-subscriptions-feature';

export const FeatureAlertSettingsCard: FC<FeatureAlertSettingsCardProps> = ({ alertFilters, fieldDisabled, getSettingsState }): JSX.Element => {
  const { axios } = useAuthenticated();
  const { t } = useTranslation(['admin', 'common']);
  const {
    alertableFeatures,
    alertableFeaturesError,
    alertableFeatureTypes,
    alertableFeatureTypesError,
  } = useAlertableFeatures();
  const [settings, setSettings] = useState<Settings>({
    alertTypeId: alertFilters.alertTypeId,
    feature: alertFilters.feature,
    featureTypeIdPrefix: alertFilters.featureTypeIdPrefix,
  });
  const filterNonAlertableData = useBoolFlag(FILTER_NON_ALERTABLE_DATA_FLAG);
  const flagUseEventV3 = useBoolFlag('portals-translation-retrieval-hook-for-event-and-alert-descriptions-feature');

  const [featureTypePrefixes, featureTypePrefixesError] = usePromise<string[]>(
    async () => {
      const result = await axios.get<string[]>('/api/v1/my/feature-type-id-prefixes');
      const data = filterNonAlertableData
        ? result.data.filter((prefix) => alertableFeatureTypes?.some(({ _id }) => _id.startsWith(prefix)))
        : result.data;
      return data.sort();
    },
    [axios, alertableFeatureTypes],
  );

  const [features, featuresError] = usePromise<string[]>(
    async () => {
      const result = await axios.get<string[]>('/api/v1/my/feature-instances', {
        params: {
          featureTypeIdPrefix: settings.featureTypeIdPrefix,
        },
      });
      const data = settings.featureTypeIdPrefix || !filterNonAlertableData
        ? result.data
        : result.data.filter((feature) => alertableFeatures?.some(({ _id }) => _id === feature));
      return data.sort();
    },
    [axios, settings.featureTypeIdPrefix, alertableFeatures],
  );

  const [alertTypes, alertTypesError] = usePromise<string[]>(
    async () => {
      const result = await axios.get<string[]>('/api/v1/my/alert-types', {
        params: {
          featureTypeIdPrefix: settings.featureTypeIdPrefix,
        },
      });
      return result.data.sort();
    },
    [axios, settings.featureTypeIdPrefix],
  );

  useEffect(() => getSettingsState(settings), [settings]);

  if (alertableFeatureTypesError || alertableFeaturesError || featureTypePrefixesError || featuresError || alertTypesError) {
    return (
      <Card>
        <CardContent>
          <Stack spacing={2}>
            <Typography variant="h5">{t('admin:page.create-subscription.feature-alert-setting.labels')}</Typography>
            <Typography>{t('admin:page.create-subscription.feature-alert-setting.hint')}</Typography>
          </Stack>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        <Stack spacing={2}>
          <Typography variant="h5">{t('admin:page.create-subscription.feature-alert-setting.labels')}</Typography>
          <FormControl>
            <TextField
              disabled={fieldDisabled}
              label={t('admin:page.subscriptions-list.feature-type.label')}
              onChange={(e) => {
                setSettings({
                  alertTypeId: null,
                  feature: null,
                  featureTypeIdPrefix: e.target.value === ALL ? null : e.target.value,
                });
              }}
              select
              size="small"
              value={settings.featureTypeIdPrefix ?? ALL}
            >
              <MenuItem value={ALL}>{t('admin:common.labels.all')}</MenuItem>
              {featureTypePrefixes?.length
                ? featureTypePrefixes.map((prefix, i) => <MenuItem key={i} value={prefix}>{getFeatureLabelWithFlag(prefix, flagUseEventV3)}</MenuItem>)
                : <MenuItem />
              }
            </TextField>
          </FormControl>
          <FormControl>
            <TextField
              disabled={fieldDisabled || !features?.length}
              label={t('common:terms.feature')}
              onChange={(e) => {
                setSettings({ ...settings, feature: e.target.value });
              }}
              select
              size="small"
              value={settings.feature ?? ''}
            >
              {features?.map((feature, i) => (
                <MenuItem key={i} value={feature}>
                  {feature.includes('camera')
                    ? t(`common:features.camera-type.${feature}`)
                    : getFeatureLabelWithFlag(feature, flagUseEventV3)}
                </MenuItem>
              )) ?? <MenuItem />}

            </TextField>
          </FormControl>
          <FormControl>
            <TextField
              disabled={settings.featureTypeIdPrefix === ALL || !settings.featureTypeIdPrefix || fieldDisabled || !alertTypes?.length}
              label={t('common:terms.alert-type')}
              onChange={(e) => setSettings({ ...settings, alertTypeId: e.target.value })}
              select
              size="small"
              value={settings.alertTypeId ?? ''}
            >
              {alertTypes?.map((alertType, i) => (
                <MenuItem key={i} value={alertType}>
                  {(settings.featureTypeIdPrefix && settings.feature) ? getEventLabelWithFlag(`${settings.featureTypeIdPrefix}-v0`, alertType, settings.feature, flagUseEventV3) : alertType}
                </MenuItem>
              )) ?? <MenuItem />}
            </TextField>
          </FormControl>
        </Stack>
      </CardContent>
    </Card>
  );
};
