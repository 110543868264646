import { AccountType, CreateAccountFromTemplateRequest } from '@eagle/core-data-types';
import { Stack, Typography } from '@mui/material';
import { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../../auth';
import { useFetchAllCache, usePromise } from '../../../hooks';
import { CacheDataTypes } from '../../../types';
import { ErrorMessage } from '../../error-message';
import { MiddleSpinner } from '../../middle-spinner';
import { SelectionCheckBox } from '../../selection-check-box';
import { useStakeholderAccountQuery } from '../life-cycle.utils';
import { StakeHolderAccountCard } from './stage-confirm-dialog-card';
import { useStageConfirmDialogContext } from './stage-confirm-dialog-context';
import { StakeholderCardBase } from './stakeholder-card-base';

export const StageConfirmDialogConfirmStep: FC = () => {
  const { state, stage, thing, dispatch } = useStageConfirmDialogContext();
  const { t } = useTranslation(['common', 'admin']);

  const sortedStakeholders = useMemo(() => {
    return [...state.stakeholders].sort((a, b) => (a.initialAccountId ? 1 : 0) - (b.initialAccountId ? 1 : 0));
  }, [state.stakeholders]);

  const handleClick = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'SET_CONFIRMATION', value: e.target.checked });
  }, [dispatch]);

  return (
    <Stack spacing={2}>
      {sortedStakeholders.map((stakeholder) => {
        if (stakeholder.isCreatingNewAccount && stakeholder.createAccount) {
          return <StakeholderItem key={stakeholder.role} stakeholderData={stakeholder.createAccount.data} stakeholderRole={stakeholder.role} />;
        }

        if (stakeholder.accountId && stakeholder.isDirty) {
          return (
            <StakeholderConfirmCard key={stakeholder.role} role={stakeholder.role} accountId={stakeholder.accountId} />
          );
        }

        return null;
      })}

      {state.renderConfirmation ? state.renderConfirmation(state) : (
        <Typography>
          {t('admin:page.thing-detail.update-stage.confirm-selection.labels', { thingDisplay: thing?.display, stageName: stage.display })}
        </Typography>
      )}
      <SelectionCheckBox
        checked={state.confirmationChecked}
        data-testid="stage-dialog-confirm-checkbox"
        handleClick={handleClick}
        label={t('admin:page.thing-detail.update-stage.selection-confirmed.labels')}
        sx={{ pl: 0 }}
      />
    </Stack>
  );
};

interface StakeholderConfirmCardProps {
  accountId: string;
  role: string;
}

const StakeholderConfirmCard: FC<StakeholderConfirmCardProps> = ({ accountId, role }) => {
  const { sharedThing, state } = useStageConfirmDialogContext();
  const { axios } = useAuthenticated();

  const { data: account, error: accountError, status: accountStatus } = useStakeholderAccountQuery({ accountId, role, sharedThingId: sharedThing._id });

  const [accountType, , accountTypeState] = usePromise(async () => {
    const response = await axios.get<AccountType>(`/api/v1/shared-thing/${sharedThing._id}/life-cycle/stakeholder/${role}/account/${accountId}/account-type`);
    return response.data;
  }, [accountId, role, sharedThing._id, axios]);

  if (accountStatus === 'pending' || accountTypeState === 'pending') return <MiddleSpinner />;
  if (accountError) return <ErrorMessage error={accountError} />;

  return (
    <StakeholderCardBase hideRole={state.isSimplifiedAccountSelect} role={role}>
      <StakeHolderAccountCard accountType={accountType} display={account.display} properties={account.properties} role={role} />
    </StakeholderCardBase>
  );
};

interface StakeholderItemProps {
  stakeholderData: CreateAccountFromTemplateRequest;
  stakeholderRole: string;
}

const StakeholderItem: FC<StakeholderItemProps> = ({ stakeholderData, stakeholderRole }) => {
  const accountTypesCache = useFetchAllCache(CacheDataTypes.ACCOUNT_TYPE);
  const [accountType, accountTypeError, accountTypeState] = usePromise(
    () => accountTypesCache.one<AccountType>(stakeholderData.accountTypeId),
    [accountTypesCache, stakeholderData.accountTypeId],
  );

  if (accountTypeState === 'pending') return <MiddleSpinner />;
  if (accountTypeError) return <ErrorMessage error={accountTypeError} />;

  return (
    <StakeholderCardBase key={stakeholderRole} role={stakeholderRole}>
      <StakeHolderAccountCard
        accountType={accountType}
        display={stakeholderData.display}
        properties={stakeholderData.properties}
        role={stakeholderRole}
      />
    </StakeholderCardBase>
  );
};
