import { LifecycleTemplate, SharedThing, Thing } from '@eagle/core-data-types';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSwitchAwareConfig } from '../../hooks';
import { FieldLoading } from '../field';
import { canSetStakeholder, useStakeholderAccountQuery } from '../life-cycle-stage-menu';
import { StageConfirmDialog } from '../life-cycle-stage-menu/stage-confirm-dialog';
import { DataRow } from '../static-data';

const SERVICE_CENTER_ROLE = 'service-centre';

interface ServiceHistoryServiceCenterProps {
  lifeCycleTemplate: LifecycleTemplate;
  onChanged?: VoidFunction;
  sharedThing: SharedThing;
  stakeholderRoles: string[];
  thing: Thing;
}

export const ServiceHistoryServiceCenter: FC<ServiceHistoryServiceCenterProps> = ({ lifeCycleTemplate, onChanged, sharedThing, stakeholderRoles, thing }) => {
  const { t } = useTranslation(['common']);
  const [isOpen, setIsOpen] = useState(false);
  const { productName } = useSwitchAwareConfig();
  const theme = useTheme();

  if (!sharedThing.lifecycleState?.stageId) {
    return null;
  }

  const currentStage = lifeCycleTemplate.stages.find((stage) => stage.stageId === sharedThing.lifecycleState?.stageId);
  if (!currentStage) {
    return null;
  }

  const currentStageHasServiceCenterStakeHolder = currentStage.stakeholders?.[SERVICE_CENTER_ROLE];
  const isButtonHidden = !currentStageHasServiceCenterStakeHolder || !canSetStakeholder(SERVICE_CENTER_ROLE, stakeholderRoles, lifeCycleTemplate);

  const serviceCenterAccountId = sharedThing.lifecycleState?.stakeholders?.[SERVICE_CENTER_ROLE];

  return (
    <>
      <Box px={3}>
        <DataRow
          data-testid="service-center"
          label={t('common:page.thing-detail.service-history.service-center.label.labels')}
          action={isButtonHidden ? undefined : (
            <Button
              data-testid="change-service-center-button"
              onClick={() => { setIsOpen(true); }}
            >
              {t('common.action.change')}
            </Button>
          )}
          value={serviceCenterAccountId ? (
            <ServiceCenterDisplay
              accountId={serviceCenterAccountId}
              sharedThing={sharedThing}
            />
          ) : (
            <Typography component="span" sx={{ color: theme.palette.text.disabled, fontStyle: 'italic' }}>
              {t('common:page.thing-detail.service-history.service-center.none.hint')}
            </Typography>
          )}
        />
      </Box>
      <StageConfirmDialog
        dialogTitle={t('common:page.thing-detail.service-history.service-center.dialog-title.labels')}
        lifecycleTemplate={lifeCycleTemplate}
        sharedThing={sharedThing}
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        onSuccess={onChanged}
        config={{
          renderConfirmation: (state) => {
            const serviceCenterStakeholder = state.stakeholders.find((stakeholder) => stakeholder.role === SERVICE_CENTER_ROLE);
            if (!serviceCenterStakeholder || !serviceCenterStakeholder.accountId) {
              return null;
            }

            if (!serviceCenterStakeholder.initialAccountId) {
              return <ConfirmationWithoutInitialAccount
                toAccountId={serviceCenterStakeholder.accountId}
                sharedThing={sharedThing}
              />;
            }

            return (
              <Confirmation
                fromAccountId={serviceCenterStakeholder.initialAccountId}
                toAccountId={serviceCenterStakeholder.accountId}
                sharedThing={sharedThing}
              />
            );
          },
          stakeholderRolesToDisplay: [SERVICE_CENTER_ROLE],
          simplifiedAccountSelect: true,
          successMessage: t('common:page.thing-detail.service-history.service-center.update.success.hint'),
          stakeholders: {
            [SERVICE_CENTER_ROLE]: {
              requiredToChange: true,
              selectPlaceholder: t('common:page.thing-detail.service-history.service-center.input-placeholder.hint'),
              removeConfirmation: {
                confirmText: t('common:page.thing-detail.service-history.service-center.third-party.confirm.labels', { productName }),
                checkboxLabel: t('common:page.thing-detail.service-history.service-center.third-party.checkbox.labels'),
              },
            },
          },
          updateStageLabel: t('common:common.action.update'),
        }}
        stakeholderRoles={stakeholderRoles}
        stageId={sharedThing.lifecycleState.stageId}
        thing={thing}
      />
    </>
  );
};

interface ServiceCenterDisplayProps {
  accountId: string;
  sharedThing: SharedThing;
}

const ServiceCenterDisplay: FC<ServiceCenterDisplayProps> = ({ accountId, sharedThing }) => {
  const { data: account, status } = useStakeholderAccountQuery({ accountId, role: SERVICE_CENTER_ROLE, sharedThingId: sharedThing._id });

  if (status !== 'success') {
    return <FieldLoading />;
  }

  return (
    <Typography component="span">{account.display}</Typography>
  );
};

interface ConfirmationProps {
  fromAccountId: string;
  toAccountId: string;
  sharedThing: SharedThing;
}

const Confirmation: FC<ConfirmationProps> = ({ fromAccountId, toAccountId, sharedThing }) => {
  const { t } = useTranslation(['common']);
  const { data: fromAccount, status: fromAccountStatus } = useStakeholderAccountQuery({ accountId: fromAccountId, role: SERVICE_CENTER_ROLE, sharedThingId: sharedThing._id });
  const { data: toAccount, status: toAccountStatus } = useStakeholderAccountQuery({ accountId: toAccountId, role: SERVICE_CENTER_ROLE, sharedThingId: sharedThing._id });

  if (fromAccountStatus !== 'success' || toAccountStatus !== 'success') {
    return null;
  }

  return (
    <Typography>{t('common:page.thing-detail.service-history.service-center.confirm-selection.labels', { fromAccount: fromAccount.display, toAccount: toAccount.display })}</Typography>
  );
};

const ConfirmationWithoutInitialAccount: FC<Omit<ConfirmationProps, 'fromAccountId'>> = ({ toAccountId, sharedThing }) => {
  const { t } = useTranslation(['common']);
  const { data: toAccount, status: toAccountStatus } = useStakeholderAccountQuery({ accountId: toAccountId, role: SERVICE_CENTER_ROLE, sharedThingId: sharedThing._id });

  if (toAccountStatus !== 'success') {
    return null;
  }

  return <Typography>{t('common:page.thing-detail.service-history.service-center.confirm-selection-without-initial.labels', { toAccount: toAccount.display })}</Typography>;
};
