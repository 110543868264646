import { Time } from '@eagle/common';
import { Account, UiTemplate } from '@eagle/core-data-types';
import { useQuery } from '@tanstack/react-query';
import { useAuthenticated } from '../auth';
import { CacheDataTypes } from '../types';
import { useFetchAllCache } from './use-cache';

interface UiTemplateResponse {
  template: object;
  loaded: boolean;
}

export const useUiTemplate = (id: string, fallback: object, additionalParams?: Record<string, string>, deps: string[] = [], enabled = true): UiTemplateResponse => {
  const { axios, userInfo } = useAuthenticated();
  const accountDetailCache = useFetchAllCache(CacheDataTypes.ACCOUNT);

  const { data: template, status } = useQuery({
    queryKey: ['ui-template', id, userInfo.accountId, additionalParams, deps],
    enabled,
    staleTime: Time.minutes(1),
    queryFn: async () => {
      const account = await accountDetailCache.one<Account>(userInfo.accountId);

      const response = await axios.get<UiTemplate[]>(`/api/v1/ui-template/${id}`, {
        params: {
          accountId: userInfo.accountId,
          domain: account?.homeDomain,
          ...additionalParams,
        },
      });
      return response.data[0]?.data ?? null;
    },
  });

  return { template: template ?? fallback, loaded: status !== 'pending' };
};
