import { Change } from '@eagle/api-types';
import { TypeDefinitionTypes } from '@eagle/common';
import { isEmpty, isEqual, xorWith } from 'lodash';
import { AppliedFilter, AppliedFilterType } from '../components/entity-search/types';
import { FetchAllCache } from '../hooks';
import { Nullable, Undefinable } from '../types';

export interface ReplacePath {
  new: string;
  old: string;
}

export interface DeletedProps {
  deleted?: Nullable<Change>;
  finish?: Nullable<Change>;
}

export const FILTER_OUT = {
  deleted: { deleted: null },
  finish: { finish: null },
  fixedId: { fixedAccountId: { '$exists': true } },
} as const;

export const NEW_ALERT_FILTER_FLAG = 'track-alert-logic-data-driven-enhancements-temporary-20230907';
export const NEW_FILTER_FLAG = 'portals-global-filtering-component-v2-temporary-20230420';
export const FILTER_SELECT_ALL_FLAG = 'filter-panel-select-all-deselect-all';

export const THING_FILTER_STORAGE_KEY = 'things';

export const isDeleted = <T extends unknown & DeletedProps>({ deleted, finish }: T): boolean => {
  return !!deleted || !!finish;
};

export const filterDeleted = <T extends unknown & DeletedProps>(items: T[]): T[] => {
  return items.filter((item) => !isDeleted(item));
};

export const filterDeletedCache = async <T extends unknown & DeletedProps & { _id: string }>(cache: FetchAllCache): Promise<T[]> => {
  const items = await cache.all<T>();
  return filterDeleted(items);
};

export const includeDeletedFilters = (includeDeleted: Undefinable<boolean>): Record<string, unknown> => {
  return !includeDeleted ? { ...FILTER_OUT.deleted } : {};
};

export const isAppliedFilterArrayEqual = (filter1: AppliedFilter<AppliedFilterType>[], filter2: AppliedFilter<AppliedFilterType>[]): boolean => {
  const filter1WithoutId = filter1.map((item) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...rest } = item;
    return { ids: undefined, ...rest };
  });
  const filter2WithoutId = filter2.map((item) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...rest } = item;
    return { ids: undefined, ...rest };
  });
  return isEmpty(xorWith(filter1WithoutId, filter2WithoutId, isEqual));
};

const getFilterValue = (filter: AppliedFilter): unknown => {
  return (filter.definition.type === 'entity' || filter.definition.type === TypeDefinitionTypes.REFERENCE) && 'id' in (filter.value as { id: unknown })
    ? (filter.value as { id: unknown }).id
    : filter.value;
};

export const alertFilterToQuery = (filters: AppliedFilter[]): Record<string, unknown> => {
  const simpleFilters: AppliedFilter<AppliedFilterType>[] = [];
  const alertTypeFilters: { feature: string; alertTypeId: string | { '$in': string[] } }[] = [];

  for (const filter of filters) {
    if (typeof filter.value === 'object' && filter.value.alertProperties) {
      const { feature, alert } = filter.value.alertProperties;

      const existing = alertTypeFilters.find((data) => data.feature === feature);
      if (existing) {
        if (typeof existing.alertTypeId === 'string') {
          existing.alertTypeId = { '$in': [existing.alertTypeId, alert] };
        }
        else {
          existing.alertTypeId.$in.push(alert);
        }
      }
      else {
        alertTypeFilters.push({ feature, alertTypeId: alert });
      }
    }
    else {
      simpleFilters.push(filter);
    }
  }

  const result: { '$and': Record<string, unknown>[] } = { '$and': [] };

  if (alertTypeFilters.length) {
    result.$and.push({ '$or': alertTypeFilters });
  }
  if (simpleFilters.length) {
    result.$and.push(filterToQuery(simpleFilters));
  }

  return result.$and.length ? result : {};
};

export const filterToQuery = (filters: AppliedFilter[], replacePaths?: ReplacePath[] | readonly ReplacePath[]): Record<string, unknown> => {
  const groupedFilters = filters.reduce((acc, filter) => {
    if (!acc[filter.propertyPath]) {
      acc[filter.propertyPath] = [];
    }

    acc[filter.propertyPath].push(filter);

    return acc;
  }, {} as Record<string, AppliedFilter[]>);

  const getNewFilterName = (filterName: string): string => {
    const newFilterName = replacePaths?.find((item) => item.old === filterName)?.new;
    return newFilterName ?? filterName;
  };

  const mongoFilters = Object.entries(groupedFilters).reduce((acc, [filterName, appliedFilters]) => {
    const newFilterName = getNewFilterName(filterName);

    if (newFilterName.startsWith('properties.') || newFilterName.startsWith('sharedProperties.')) {
      const propertyFilters = [];
      const values = appliedFilters.map((filter) => getFilterValue(filter));
      const filter = values.length === 1 ? values[0] : { '$in': values };
      propertyFilters.push({ [newFilterName]: filter }, { [`${newFilterName}.value`]: filter });
      acc.$and.push({ '$or': propertyFilters });
    }
    else if (appliedFilters.length === 1) {
      acc.$and.push({ [newFilterName]: getFilterValue(appliedFilters[0]) });
    } else {
      acc.$and.push({ [newFilterName]: { '$in': appliedFilters.map((filter) => getFilterValue(filter)) } });
    }
    return acc;
  }, { '$and': [] } as { '$and': Record<string, unknown>[] });

  return !isEqual(mongoFilters, { '$and': [] }) ? mongoFilters : {};
};
