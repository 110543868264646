import { AlertResponse, Subscription } from '@eagle/core-data-types';
import { Paper, TableCell, Tooltip, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { useHref } from 'react-router-dom';
import { getAlertTypeStyle, getEventHint, getEventLabelWithFlag, getFeatureLabelWithFlag, getFeatureType } from '../../util';
import { useBoolFlag } from '../flags';
import { ResultsTableRow } from '../search-results-table';
import { COLUMN_WIDTH } from './subscription-list-desktop-view';
import { SubscriptionUserRecipientDataType } from './subscriptions.types';
import { getEntityData, RecipientDisplay, SourceDisplay } from './subscriptions.util';

interface SubscriptionsTableRowProps {
  disableLink?: boolean;
  userRecipientDataType: SubscriptionUserRecipientDataType;
  subscription: Subscription;
  subscriptionDetailPath: string;
  'data-testid'?: string;
}

export const SubscriptionsTableRow: FC<SubscriptionsTableRowProps> = ({ subscription, disableLink, subscriptionDetailPath, userRecipientDataType, ...props }) => {
  const href = useHref(subscriptionDetailPath);
  const { _id, alertFilters, recipient } = subscription;
  const { alertTypeId, feature } = alertFilters;
  const theme = useTheme();
  const flagUseEventV3 = useBoolFlag('portals-translation-retrieval-hook-for-event-and-alert-descriptions-feature');
  const isHintEnabled = useBoolFlag('portals-event-translation-hints-feature');
  const featureTypeId = getFeatureType(alertTypeId);

  const renderContent = (): JSX.Element => <>
    <TableCell component="div" sx={{ width: COLUMN_WIDTH }} data-testid="subscription-recipient">
      <RecipientDisplay userDataType={userRecipientDataType} recipient={recipient} />
    </TableCell>

    <TableCell component="div" sx={{ width: COLUMN_WIDTH }} data-testid="subscription-entity">
      <SourceDisplay entityData={getEntityData(alertFilters)} />
    </TableCell>

    <TableCell component="div" sx={{ width: COLUMN_WIDTH }}>
      <Typography data-testid="subscription-feature">{feature ? getFeatureLabelWithFlag(feature, flagUseEventV3) : feature}</Typography>
    </TableCell>

    <TableCell component="div" sx={{ width: COLUMN_WIDTH }}>
      {alertTypeId && featureTypeId && feature ?
        isHintEnabled ? (
          <Tooltip title={getEventHint(feature, alertTypeId)} placement="bottom" arrow>
            <Paper
              data-testid="subscription-alert-description"
              elevation={0}
              sx={{
                ...getAlertTypeStyle({ alertTypeId, featureTypeId, feature } as AlertResponse, theme),
                display: 'flex',
                justifyContent: 'center',
                p: '0.5rem',
                textAlign: 'center',
              }}
            >
              {getEventLabelWithFlag(featureTypeId, alertTypeId, feature, flagUseEventV3)}
            </Paper>
          </Tooltip>
        ) : (
          <Paper
            data-testid="subscription-alert-description"
            elevation={0}
            sx={{
              ...getAlertTypeStyle({ alertTypeId, featureTypeId, feature } as AlertResponse, theme),
              display: 'flex',
              justifyContent: 'center',
              p: '0.5rem',
              textAlign: 'center',
            }}
          >
            {getEventLabelWithFlag(featureTypeId, alertTypeId, feature, flagUseEventV3)}
          </Paper>
        ) : null
      }
    </TableCell>
  </>;

  return (
    <ResultsTableRow
      content={renderContent()}
      href={disableLink ? undefined : `${href}/${_id}`}
      data-testid={props['data-testid']}
    />
  );
};
