/* eslint-disable react-hooks/exhaustive-deps */
import { Button, FormControl, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../auth';
import { useBoolFlag } from '../../components';
import { usePromise } from '../../hooks';
import { getEventLabelWithFlag, getFeatureLabelWithFlag, useAlertableFeatures } from '../../util';
import { FILTER_NON_ALERTABLE_DATA_FLAG } from '../subscription/feature-alert-settings-card';
import { FeatureAlertSettings } from './create-subscription.types';

interface Props {
  onActiveBack: () => void;
  onActiveNext: () => void;
  updateFeatureAlertSettings: (settings: FeatureAlertSettings) => void;
}

const ALL = 'all';

export const CreateSubscriptionFeatureAlertSettings: FC<Props> = ({ onActiveBack, onActiveNext, updateFeatureAlertSettings }): JSX.Element => {
  const { axios } = useAuthenticated();
  const { t } = useTranslation(['admin', 'common']);
  const {
    alertableFeatures,
    alertableFeaturesError,
    alertableFeatureTypes,
    alertableFeatureTypesError,
  } = useAlertableFeatures();
  const [settings, setSettings] = useState<FeatureAlertSettings>({
    alertTypeId: null,
    feature: null,
    featureTypeIdPrefix: null,
  });
  const filterNonAlertableData = useBoolFlag(FILTER_NON_ALERTABLE_DATA_FLAG);
  const flagUseEventV3 = useBoolFlag('portals-translation-retrieval-hook-for-event-and-alert-descriptions-feature');

  const [featureTypePrefixes, featureTypePrefixesError] = usePromise<string[]>(
    async () => {
      const result = await axios.get<string[]>('/api/v1/my/feature-type-id-prefixes');
      const data = filterNonAlertableData
        ? result.data.filter((prefix) => alertableFeatureTypes?.some(({ _id }) => _id.startsWith(prefix)))
        : result.data;
      return data.sort();
    },
    [axios, alertableFeatureTypes],
  );

  const [features, featuresError] = usePromise<string[]>(
    async () => {
      const result = await axios.get<string[]>('/api/v1/my/feature-instances', {
        params: {
          featureTypeIdPrefix: settings.featureTypeIdPrefix,
        },
      });
      const data = settings.featureTypeIdPrefix || !filterNonAlertableData
        ? result.data
        : result.data.filter((feature) => alertableFeatures?.some(({ _id }) => _id === feature));
      return data.sort();
    },
    [axios, settings.featureTypeIdPrefix, alertableFeatures],
  );

  const [alertTypes, alertTypesError] = usePromise<string[]>(
    async () => {
      const result = await axios.get<string[]>('/api/v1/my/alert-types', {
        params: {
          featureTypeIdPrefix: settings.featureTypeIdPrefix,
        },
      });
      return result.data.sort();
    },
    [axios, settings.featureTypeIdPrefix],
  );

  useEffect(() => updateFeatureAlertSettings(settings), [settings]);

  if (alertableFeatureTypesError || alertableFeaturesError || featureTypePrefixesError || featuresError || alertTypesError) {
    return (
      <Stack spacing={2}>
        <Typography variant="h5">{t('admin:page.create-subscription.feature-alert-setting.labels')}</Typography>
        <Typography>{t('admin:page.create-subscription.feature-alert-setting.hint')}</Typography>
      </Stack>
    );
  }

  return (
    <Stack spacing={2}>
      <Typography variant="h5">{t('admin:page.create-subscription.feature-alert-setting.labels')}</Typography>
      <FormControl>
        <TextField
          label={t('admin:page.subscriptions-list.feature-type.label')}
          onChange={(e) => {
            setSettings({
              alertTypeId: null,
              feature: null,
              featureTypeIdPrefix: e.target.value === ALL ? null : e.target.value,
            });
          }}
          select
          size="small"
          value={settings.featureTypeIdPrefix ?? ALL}
          data-testid='dropdown-all-select'
        >
          <MenuItem value={ALL}>{t('admin:common.labels.all')}</MenuItem>
          {featureTypePrefixes?.length
            ? featureTypePrefixes.map((prefix, i) => <MenuItem key={i} value={prefix} data-testid={`dropdown-menu-item-${i}`}>{getFeatureLabelWithFlag(prefix, flagUseEventV3)}</MenuItem>)
            : <MenuItem />
          }
        </TextField>
      </FormControl>
      <FormControl>
        <TextField
          disabled={!features?.length}
          label={t('common:terms.feature')}
          onChange={(e) => {
            setSettings({ ...settings, feature: e.target.value });
          }}
          select
          size="small"
          value={settings.feature ?? ''}
          data-testid='dropdown-menu-select'
        >
          {features?.map((feature, i) => (
            <MenuItem key={i} value={feature} data-testid={`dropdown-menu-item-${i}`}>
              {feature.includes('camera')
                ? t(`common:features.camera-type.${feature}`)
                : getFeatureLabelWithFlag(feature, flagUseEventV3)}
            </MenuItem>
          )) ?? <MenuItem />}

        </TextField>
      </FormControl>
      <FormControl>
        <TextField
          disabled={!settings.featureTypeIdPrefix || !alertTypes?.length}
          label={t('common:terms.alert-type')}
          onChange={(e) => setSettings({ ...settings, alertTypeId: e.target.value })}
          select
          size="small"
          value={settings.alertTypeId ?? ''}
          data-testid='dropdown-menu-select'
        >
          {alertTypes?.map((alertType, i) => (
            <MenuItem key={i} value={alertType} data-testid={`dropdown-menu-item-${i}`}>
              {(settings.featureTypeIdPrefix && settings.feature) ? getEventLabelWithFlag(`${settings.featureTypeIdPrefix}-v0`, alertType, settings.feature, flagUseEventV3) : alertType}
            </MenuItem>
          )) ?? <MenuItem />}
        </TextField>
      </FormControl>

      <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end', my: 1 }}>
        <Button
          onClick={onActiveBack}
          variant="outlined"
          data-testid="button-back"
        >
          {t('common:common.action.back')}
        </Button>
        <Button
          onClick={onActiveNext}
          variant="contained"
          data-testid="button-next"
        >
          {t('common:common.action.next')}
        </Button>
      </Stack>
    </Stack>
  );
};
