import { LifecycleTemplate, SharedThing, Stage, Thing } from '@eagle/core-data-types';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, MouseEventHandler, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../auth';
import { useFetchOneCache, usePromise, usePromiseConditional } from '../../hooks';
import { CacheDataTypes, Nullable, Undefinable } from '../../types';
import { testid } from '../../util';
import { StageConfirmDialog } from './stage-confirm-dialog';

interface Props {
  renderButton?: (params: { onClick: MouseEventHandler<HTMLButtonElement> }) => ReactNode;
  sharedThing?: SharedThing;
  sx?: SxProps;
  thing?: Thing;
  onSuccess?: (hasDeleteAction: boolean) => void;
}

export const StageSelectionMenu: FC<Props> = ({ sharedThing, sx, thing, renderButton, onSuccess }) => {
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLElement>>(null);
  const { t } = useTranslation(['common', 'admin']);
  const { axios } = useAuthenticated();
  const [selectedStageId, setSelectedStageId] = useState<Nullable<string>>(null);
  const lifecycleTemplateCache = useFetchOneCache(CacheDataTypes.LIFECYCLE_TEMPLATE);
  const isOpen = Boolean(anchorEl);

  const [lifecycleTemplate] = usePromise<Undefinable<LifecycleTemplate>>(async () => {
    if (!sharedThing?.lifecycleTemplateId) return;
    return lifecycleTemplateCache.one(sharedThing.lifecycleTemplateId);
  }, [lifecycleTemplateCache, sharedThing]);

  const [stages] = usePromiseConditional<Stage[] | null>(async () => {
    if (!sharedThing) {
      return null;
    }
    const response = await axios.get<Stage[]>(`/api/v1/shared-thing/${sharedThing?._id}/life-cycle/next-stages`);
    return response.data;
  }, () => isOpen, [axios, sharedThing, isOpen]);

  const [stakeholderRoles] = usePromiseConditional<string[] | null>(async () => {
    if (!lifecycleTemplate) {
      return null;
    }
    const response = await axios.get<string[]>(`/api/v1/my/life-cycle-template/${lifecycleTemplate._id}/stakeholders`);
    return response.data;
  }, () => isOpen, [axios, lifecycleTemplate, isOpen]);

  const onButtonClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const renderMenuItems = (): JSX.Element[] | JSX.Element => {
    if (!stages || !stakeholderRoles) {
      return <Typography sx={{ px: 2, py: '6px' }}>{t('common:common.labels.loading')}</Typography>;
    }

    if (stages.length === 0) {
      return <Typography sx={{ px: 2, py: '6px' }}>{t('admin:page.thing-details.update-stage.hint.no-stage')}</Typography>;
    }

    return stages.map((stage, i) => (
      <MenuItem data-testid={testid`update-stage-menu-item-${stage.stageId}`} key={i} onClick={() => {
        setAnchorEl(null);
        setSelectedStageId(stage.stageId);
      }}>
        {stage.display}
      </MenuItem>
    ));
  };

  return <>
    {renderButton ? renderButton({ onClick: onButtonClick }) : (
      <Button data-testid="update-stage-button" onClick={onButtonClick} sx={{ ...sx, whiteSpace: 'nowrap' }}>
        {t('admin:page.thing-detail.update-stage.action')}
      </Button>
    )}
    <Menu
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      open={!!anchorEl}
      PaperProps={{ sx: { minWidth: 200 } }}
    >
      {renderMenuItems()}
    </Menu>
    {sharedThing && lifecycleTemplate && stakeholderRoles && stages?.map((stage) => (
      <StageConfirmDialog
        lifecycleTemplate={lifecycleTemplate}
        key={stage.stageId}
        open={selectedStageId === stage.stageId}
        onClose={() => {
          setSelectedStageId(null);
        }}
        onSuccess={onSuccess}
        sharedThing={sharedThing}
        stakeholderRoles={stakeholderRoles}
        stageId={stage.stageId}
        thing={thing}
      />
    ))}
  </>;
};
