/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Link, Stack, Typography, useTheme } from '@mui/material';
import { captureException } from '@sentry/react';
import chroma from 'chroma-js';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthStatus, useAuth } from '../../auth';
import { Alert } from '../../components/alert';
import { LoadingButton } from '../../components/loading-button';
import { EthingsLogo } from '../../components/logo';
import { useConfig, useSmallScreen } from '../../hooks';
import { LandingView } from './view';

interface LandingControllerProps {
  portalName: string;
}

export const LandingPageController: FC<LandingControllerProps> = ({ portalName }) => {
  const { termsOfService, productName } = useConfig();
  const { signIn, signOut, state } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const smallScreen = useSmallScreen();
  const { i18n, t } = useTranslation(['common']);
  const theme = useTheme();
  const [configError, setConfigError] = useState(false);
  const signInParam = 'signIn';

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const redirectAfterSignOut = params.get(signInParam);
    if (redirectAfterSignOut !== 'true') return;
    setConfigError(true);
    window.history.replaceState(null, '', window.location.pathname);
    signIn()
      .then(() => {
        setConfigError(false);
      })
      .catch((err) => {
        console.error(err);
        captureException(err);
      });
  }, []);

  useEffect(() => {
    if (state?.status === AuthStatus.CONFIG_ERROR) {
      setConfigError(true);
      return;
    }
    if (state?.status === AuthStatus.AUTHENTICATION_FAILED) {
      enqueueSnackbar(i18n.exists(`auth-errors:${state.failure.error}`)
        ? t(`auth-errors:${state.failure.error}`)
        : state.failure.errorDescription, { variant: 'error' });
    }
  }, [state]);

  const renderLogo = (logoUrl: string): JSX.Element => (
    logoUrl.length ? <img src={logoUrl} alt={productName ? `${productName} logo` : 'logo'} width="100%" /> : <EthingsLogo logo={theme.landingPage.logo} width='250px' />
  );

  const renderHeader = (headerText: string): JSX.Element => (
    <Typography id="header" variant="h3" sx={{ whiteSpace: 'pre-line' }}>{headerText}</Typography>
  );

  const renderSubHeader = (subHeaderText: string): JSX.Element => (
    <Typography id='sub-header' variant="h5">{subHeaderText}</Typography>
  );

  const renderSignInButton = (): JSX.Element => (
    <Stack spacing={3} alignItems='center' sx={{ width: smallScreen ? '100%' : 'auto' }}>
      {!configError && (
        <LoadingButton
          data-testid='sign-in-button'
          fullWidth={smallScreen}
          size='large'
          task={() => signIn()}
          variant='contained'
          sx={{
            color: theme.landingPage.signInButton.contrastText,
            backgroundColor: theme.landingPage.signInButton.background,
            '&:hover': {
              backgroundColor: chroma(theme.landingPage.signInButton.background).darken(0.5).hex(),
            },
          }}
        >
          {t('common:component.auth.labels.sign-in')}
        </LoadingButton>
      )}
      {state?.status === AuthStatus.CONFIG_ERROR && (
        <Stack spacing={2} alignItems='center' sx={{ width: '100%' }}>
          <Alert
            data-testid="config-error"
            severity="error"
            sx={{
              width: '100%',
              '& .MuiAlert-message': {
                width: '100%',
              },
            }}
          >
            {t('common:component.auth.error.config-error')}
          </Alert>
          <Button
            data-testid='sign-in-again-button'
            onClick={() => signOut(signInParam)}
            fullWidth={smallScreen}
            size='large'
            variant='contained'
            sx={{
              color: theme.landingPage.signInButton.contrastText,
              backgroundColor: theme.landingPage.signInButton.background,
              '&:hover': {
                backgroundColor: chroma(theme.landingPage.signInButton.background).darken(0.5).hex(),
              },
            }}
          >
            {t('common:component.auth.labels.sign-in-again')}
          </Button>
        </Stack>
      )}
    </Stack>
  );

  const renderFooterLogo = (logoUrl: string): JSX.Element => (
    <Stack id="footer-logo" direction="row">
      {logoUrl.length ?
        <img src={logoUrl} width="100px" alt="Footer logo" />
        : <>
          <EthingsLogo logo={theme.landingPage.footer.logo} width='120px' />
          <Typography color={theme.landingPage.footer.logo.includes('light') ? '#ffffff' : '#231F20'} variant="caption" sx={{ fontSize: '0.3rem', mt: 1 }}>TM</Typography>
        </>}
    </Stack>
  );

  const renderTopFooterText = (topFooterText: string): JSX.Element => (
    <Typography id="footer-top-text" color={theme.landingPage.footer.contrastText} variant="caption">{topFooterText}</Typography>
  );

  const renderBottomFooterText = (bottomFooterText: string): JSX.Element => {
    return <Typography id="footer-bottom-text" color={theme.landingPage.footer.contrastText} variant="caption">{bottomFooterText}</Typography>;
  };

  const renderTermsAndConditionsText = (): JSX.Element => {
    if (!termsOfService?.documentId || !productName) return <></>;
    const origin = window.location.origin;
    const domain = window.location.host;
    const termsLink = `${origin}/web-asset/${termsOfService.documentId}?domain=${domain}`;

    return (
      <Stack alignItems="center" sx={{ mt: 2 }}>
        <Typography
          variant="body2"
          data-testid="terms-and-conditions-text"
          sx={{ color: theme.landingPage.footer.contrastText }}
        >
          {t('common:component.landing.labels.domain-terms-and-conditions', { account: productName })}
          {' '}
          <Link
            href={termsLink}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              color: theme.landingPage.footer.contrastText,
              textDecoration: 'underline',
              '&:hover': {
                color: theme.palette.primary.dark,
              },
            }}
          >
            {t('common:component.landing.action.terms-and-conditions')}
          </Link>
        </Typography>
      </Stack>
    );
  };

  return (
    <LandingView
      portalName={portalName}
      renderBottomFooterText={renderBottomFooterText}
      renderFooterLogo={renderFooterLogo}
      renderHeader={renderHeader}
      renderLogo={renderLogo}
      renderSignInButton={renderSignInButton}
      renderSubHeader={renderSubHeader}
      renderTermsAndConditionsText={renderTermsAndConditionsText}
      renderTopFooterText={renderTopFooterText}
      smallScreen={smallScreen}
    />
  );
};
