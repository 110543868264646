import { InputAdornment, TextField } from '@mui/material';
import React, { forwardRef, RefObject, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import validator from 'validator';
import { usePhoneDigits } from '../../hooks/phone-input/use-phone-digits';
import { useGeoLocation } from '../../hooks/use-geolocation';
import { Nullable } from '../../types';
import { assocRefToPropRef } from '../../util/phone-input';
import { DEFAULT_COUNTRY_ISO, DEFAULT_LANG } from './constants';
import FlagButton from './flag-button';
import FlagsMenu from './flags-menu';
import { PhoneInputCountry, PhoneInputProps } from './types';

export const PhoneInput = forwardRef((props: PhoneInputProps, propRef: PhoneInputProps['ref']): JSX.Element => {
  const { t, i18n } = useTranslation(['common']);
  const { country_code: countryCode } = useGeoLocation();
  const {
    forceCallingCode = false,
    defaultCountry = countryCode?.length === 2 ? countryCode as PhoneInputCountry : DEFAULT_COUNTRY_ISO,
    onlyCountries,
    excludedCountries,
    preferredCountries,
    value,
    inputProps,
    InputProps,
    inputRef: inputRefFromProps,
    disabled,
    onChange,
    disableDropdown = false,
    disableFormatting = false,
    localization = i18n.language ?? DEFAULT_LANG,
    MenuProps,
    className,
    mobileOnly = false,
    enableSearch = true,
    searchLabel = t('common:component.search.labels.label'),
    label = t('common:common.labels.phone'),
    helperText = t('common:common.hint.phone'),
    ...restTextFieldProps
  } = props;
  const textFieldRef = useRef<Nullable<HTMLDivElement>>(null);
  const inputRef = useRef<Nullable<HTMLInputElement>>(null);
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLDivElement>>(null);

  const { onInputChange, onCountryChange, isoCode, inputValue, isValid } = usePhoneDigits({
    defaultCountry,
    value,
    onChange,
    forceCallingCode,
    excludedCountries,
    onlyCountries,
    disableFormatting,
  });

  const isValidMobilePhone = validator.isMobilePhone(inputValue);
  const isValidPhoneNumber = mobileOnly ? isValidMobilePhone && isValid : isValid;
  const errorText = !isValid ? helperText : mobileOnly && !isValidMobilePhone ? t('common:component.phone-input.hint.mobile-phone') : undefined;

  const handleOpenFlagsMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.preventDefault();
    if (!disabled || !disableDropdown) {
      setAnchorEl(textFieldRef.current);
    }
  };

  const focusInputElement = (): void => {
    setTimeout(() => {
      if (inputRef.current) inputRef.current.focus();
    }, 10);
  };

  const handleChangeCountry = (newCountry: PhoneInputCountry): void => {
    setAnchorEl(null);
    onCountryChange(newCountry);
    focusInputElement();
  };

  const handleRefInput = (ref: RefObject<HTMLInputElement> | HTMLInputElement): void => {
    if (ref instanceof HTMLInputElement) inputRef.current = ref;
    if (InputProps?.inputRef) {
      assocRefToPropRef(ref, InputProps.inputRef);
    }
    if (inputRefFromProps) {
      assocRefToPropRef(ref, inputRefFromProps);
    }
  };

  const handleRef = (ref: Nullable<HTMLDivElement>): void => {
    textFieldRef.current = ref;
    if (propRef) {
      assocRefToPropRef(ref, propRef);
    }
  };

  const handleCloseFlagsMenu = (): void => {
    setAnchorEl(null);
  };

  const onInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    const target = e.target as HTMLInputElement;
    if (e.key === 'Enter') target.blur();
  };

  return (
    <>
      <TextField
        className={`PhoneInput-TextField ${className || ''}`}
        data-testid={props['data-testid']}
        disabled={disabled}
        error={!isValidPhoneNumber}
        helperText={!isValidPhoneNumber ? errorText : undefined}
        inputProps={{ ...inputProps }}
        inputRef={handleRefInput}
        label={label}
        onChange={onInputChange}
        onKeyDown={restTextFieldProps.onKeyDown ?? onInputKeyDown}
        ref={handleRef}
        type="tel"
        value={inputValue}
        InputProps={{
          ...InputProps,
          startAdornment: (
            <InputAdornment position="start" sx={{ marginLeft: -0.5 }}>
              <FlagButton
                isFlagsMenuOpened={Boolean(anchorEl)}
                isoCode={isoCode}
                onClick={handleOpenFlagsMenu}
                disabled={disabled}
                disableDropdown={Boolean(disableDropdown)}
              />
            </InputAdornment>
          ),
        }}
        {...restTextFieldProps}
      />
      {!disableDropdown ? (
        <FlagsMenu
          onlyCountries={onlyCountries}
          excludedCountries={excludedCountries}
          anchorEl={anchorEl}
          isoCode={isoCode}
          preferredCountries={preferredCountries}
          onClose={handleCloseFlagsMenu}
          localization={localization}
          onSelectCountry={handleChangeCountry}
          enableSearch={enableSearch}
          searchLabel={searchLabel}
          {...MenuProps}
        />
      ) : null}
    </>
  );
});

PhoneInput.displayName = 'PhoneInput';
