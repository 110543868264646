/* eslint-disable react-hooks/exhaustive-deps */
import { BoundingBox, Time } from '@eagle/common';
import { Thing, ThingLastLocation, ThingType } from '@eagle/core-data-types';
import { Box } from '@mui/system';
import { captureException } from '@sentry/react';
import L from 'leaflet';
import { DateTime } from 'luxon';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Marker, useMap } from 'react-leaflet';
import { useParams } from 'react-router';
import Supercluster from 'supercluster';
import useSupercluster from 'use-supercluster';
import { useAuthenticated } from '../../auth/auth';
import { CLUSTER_BOUNDS_PADDING, MAP_MAX_ZOOM } from '../../constants';
import { evaluate } from '../../evaluator';
import { LastThingEvents, LastThingEventsByFeature, useGeoLocation, useLastThingState, useUiTemplate } from '../../hooks';
import { useSearch } from '../../pages/list/use-search';
import { CacheDataTypes } from '../../types';
import { Nullable } from '../../types/common';
import { DEFAULT_MAP_MARKER } from '../../ui-templates';
import { areBoundsInside, areObjectsEqual, getThingLocations, MapPosition, toBBox, wrapLongIfNecessary } from '../../util/maps';
import { FetchOne, FetchOneOfAll } from '../fetch';
import { useMapLayers } from './hooks';
import './leaflet-styles.min.css';
import { MarkerCluster, MarkerPoint, PointProperties } from './page-map.types';
import { SpiderMarkers } from './spider-markers';

const FIT_BOUNDS_PADDING = 125;

interface Props {
  disableMarkerClick?: boolean;
  enableSettingPosition?: boolean;
  savedPosition?: Nullable<MapPosition>;
  loading?: boolean;
  setLoading?: (loading: boolean) => void;
  setLocationLoading?: (loading: boolean) => void;
  setSavedPosition?: (position: MapPosition) => void;
  handleNoLocations: () => void;
  handleLoadingError: () => void;
}

interface MarkerProps {
  disableMarkerClick?: boolean;
  fetchState: (thingId: string) => Promise<LastThingEvents | undefined>;
  position: L.LatLng;
  thingId: string;
  template: object;
}

interface RenderFactoryProps {
  disableMarkerClick?: boolean;
  lastThingEvents?: LastThingEventsByFeature;
  position: L.LatLng;
  template: object;
  thing: Thing;
  thingId: string;
  thingType: ThingType;
}

const MAP_REFRESH_INTERVAL_SECONDS = Time.seconds(Number(import.meta.env.VITE_MAP_RELOAD)) || 300;

const RenderFactory: FC<RenderFactoryProps> = ({ disableMarkerClick, lastThingEvents, position, template, thing, thingId, thingType }) => {
  const mapMarker = evaluate(template, { disableMarkerClick, position, entityId: thingId, 'data-testid': `map-marker-${thingId}`, dataType: CacheDataTypes.THING, lastEvents: lastThingEvents, thingType, thing });

  return (<>{mapMarker ?? null}</>);
};

const ThingMarker: FC<MarkerProps> = ({ disableMarkerClick, fetchState, position, thingId, template }) => {
  const [lastThingEvents, setLastThingEvents] = useState<LastThingEventsByFeature>();

  useEffect(() => {
    if (!thingId) return;

    setLastThingEvents(undefined);

    fetchState(thingId).then((events) => {
      setLastThingEvents(events?.byFeature);
    }).catch(captureException);
  }, [fetchState, thingId]);

  return <FetchOne id={thingId} dataType={CacheDataTypes.THING} notFoundFactory={() => <></>} renderFactory={(thing: Thing) =>
    <FetchOneOfAll
      id={thing.thingTypeId}
      notFoundFactory={() => <></>}
      dataType={CacheDataTypes.THING_TYPE}
      renderFactory={(thingType: ThingType) => (
        <RenderFactory
          disableMarkerClick={disableMarkerClick}
          lastThingEvents={lastThingEvents}
          position={position}
          template={template}
          thing={thing}
          thingId={thingId}
          thingType={thingType}
        />
      )}
    />}
  />;
};

export const ThingEntities = ({
  disableMarkerClick,
  enableSettingPosition = true,
  savedPosition,
  setLoading,
  setLocationLoading,
  handleNoLocations,
  handleLoadingError,
}: Props): JSX.Element | null => {
  const map = useMap();
  const { isThingsLayerSelected } = useMapLayers();
  const { latitude: geoLat, longitude: geoLong, loading: geoLoading } = useGeoLocation();
  const { axios } = useAuthenticated();
  const { filters } = useSearch();
  const { thingId } = useParams();
  const debounceTimeout = useRef<Nullable<number>>(null);
  const [callApi, setCallApi] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(DateTime.now());
  const [maxUpdated, setMaxUpdated] = useState<Date | undefined>(undefined);
  const [locationsMap, setLocationsMap] = useState<Nullable<Map<string, ThingLastLocation>>>(null);
  const [bounds, setBounds] = useState<BoundingBox | null>(() => getInitialBounds(map));
  const [currentBounds, setCurrentBounds] = useState<BoundingBox | null>(() => getInitialBounds(map));
  const isInitialPositionSet = useRef(false);
  const [fetchState] = useLastThingState();

  const [points, setPoints] = useState<MarkerPoint[]>([]);
  const [selectedCluster, setSelectedCluster] = useState<MarkerCluster>();
  const [spiders, setSpiders] = useState<MarkerPoint[]>([]);
  const [zoom, setZoom] = useState(map.getZoom());
  const { template, loaded } = useUiTemplate('map-marker', DEFAULT_MAP_MARKER);

  const { clusters, supercluster } = useSupercluster<PointProperties, PointProperties>({
    points,
    bounds: currentBounds ? toBBox(currentBounds) : [-180, -90, 180, 90],
    zoom,
    options: { maxZoom: MAP_MAX_ZOOM, radius: 120 },
  });

  const isCluster = (cluster: MarkerCluster | MarkerPoint): cluster is MarkerCluster => cluster.properties.cluster;

  const DEFAULT_MAP_CENTER = L.latLng(0, 0);

  const getBoundsOfArray = (tmpBounds: L.LatLng[]): L.LatLngBounds => {
    const latArray = tmpBounds.map(({ lat }) => lat);
    const lngArray = tmpBounds.map(({ lng }) => lng);

    const bottomLeft = new L.LatLng(Math.min.apply(null, latArray), Math.min.apply(null, lngArray));
    const topRight = new L.LatLng(Math.max.apply(null, latArray), Math.max.apply(null, lngArray));
    return new L.LatLngBounds(bottomLeft, topRight);
  };

  useEffect(() => {
    if (enableSettingPosition && !thingId && !isInitialPositionSet.current) {
      if (savedPosition) { // 1. saved location
        const { lat, lng, alt } = savedPosition;
        map.setView(new L.LatLng(lat, lng), alt, { animate: false });
        isInitialPositionSet.current = true;
      }
      else if (locationsMap) {
        const initialZoom = map.getZoom();
        const hasLocations = locationsMap.size > 0;
        if (hasLocations) { // 2. location based on data
          const newBounds = getBoundsOfArray(Array.from(locationsMap.values()).map((location) => new L.LatLng(location.latitude, location.longitude)));
          map.fitBounds(newBounds, { animate: false, padding: new L.Point(FIT_BOUNDS_PADDING, FIT_BOUNDS_PADDING) });
        } else if (geoLat && geoLong && !geoLoading) { // 3. location based on current position
          map.setView(new L.LatLng(geoLat, geoLong), undefined, { animate: false });
        } else { // 4. default location
          map.setView(DEFAULT_MAP_CENTER, undefined, { animate: false });
        }
        if (map.getZoom() === initialZoom) {
          // Fire a zoomend event even if the zoom hasn't changed to trigger saving the position to localStorage.
          map.fire('zoomend');
        }
        if (handleNoLocations && !hasLocations) {
          handleNoLocations();
        }
        isInitialPositionSet.current = true;
      }
    }
  }, [map, locationsMap, enableSettingPosition, thingId, handleNoLocations]);

  useEffect(() => {
    const checkAndUpdateData = (): void => {
      if (DateTime.now().diff(lastUpdate, 'seconds').seconds > MAP_REFRESH_INTERVAL_SECONDS) {
        setCallApi(true);
        map.fire('dataLoaded');
      }
    };

    // Interval to check every X minutes
    const intervalId = setInterval(checkAndUpdateData, MAP_REFRESH_INTERVAL_SECONDS);

    // Event listener for tab visibility
    const handleVisibilityChange = (): void => {
      if (document.visibilityState === 'visible') {
        checkAndUpdateData();
      }
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup
    return () => {
      clearInterval(intervalId);  // Clear the interval
      document.removeEventListener('visibilitychange', handleVisibilityChange);  // Remove the event listener
    };
  }, [lastUpdate]);

  useEffect(() => {
    const bounds = map.getBounds();
    if (bounds.isValid()) {
      setBounds(boundsToBoundingBox(bounds));
    }
  }, [thingId, filters]);

  useEffect(() => {
    const handleBoundsChange = (): void => {
      const tmpCurrentBounds = map.getBounds();
      const boundsToUpdate = boundsToBoundingBox(tmpCurrentBounds);

      // Always update the current bounds
      setCurrentBounds(boundsToUpdate);

      if (bounds && tmpCurrentBounds.isValid() && !areObjectsEqual(boundsToUpdate, bounds)) {
        if (!areBoundsInside(boundsToUpdate, bounds)) {
          setBounds(boundsToUpdate);
        }
      }
    };

    const handleZoomChange = (): void => {
      const currentZoom = map.getZoom();
      setZoom(currentZoom);
      if (currentZoom === MAP_MAX_ZOOM) return;
      map.fire('unspiderfy');
    };

    map.on('dragend', handleBoundsChange);
    map.on('moveend', handleBoundsChange);
    map.on('zoomend', handleBoundsChange);
    map.on('zoomend', handleZoomChange);

    return () => {
      map.off('dragend', handleBoundsChange);
      map.off('moveend', handleBoundsChange);
      map.off('zoomend', handleBoundsChange);
      map.off('zoomend', handleZoomChange);
    };
  }, [map, bounds]);

  const handleLoading = (refreshCall: boolean): void => {
    if (debounceTimeout.current !== null) {
      clearTimeout(debounceTimeout.current);
    }

    const newTimeout = window.setTimeout(() => {
      getThingLocations(map.getBounds(), axios, locationsMap, handleLoadingError, setLocationsMap, setLastUpdate, setMaxUpdated, setLoading, setLocationLoading, maxUpdated, filters, refreshCall);
    }, 300);

    debounceTimeout.current = newTimeout;
  };

  useEffect(() => {
    handleLoading(false);
    return () => {
      if (debounceTimeout.current !== null) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [bounds, callApi]);

  useEffect(() => {
    if (callApi) {
      handleLoading(true);
      setCallApi(false);
    }
  }, [callApi]);

  useEffect(() => {
    if (!locationsMap) return;
    setPoints(
      Array
        .from(locationsMap.values())
        .map((location) => ({
          geometry: {
            coordinates: [
              location.longitude,
              location.latitude,
            ],
            type: 'Point',
          },
          properties: {
            cluster: false,
            markerThingId: location.thingId,
            updated: DateTime.now().toISO(),
          },
          type: 'Feature',
        })),
    );
  }, [locationsMap]);

  const createClusterIcon = (count: number, countWord: string, id: number): L.DivIcon => {
    const size =
      count < 100 ? 'small' :
        count < 1000 ? 'medium' : 'large';
    const icon = L.divIcon({
      className: 'marker-cluster marker-cluster-' + size,
      html: `<div data-testid="cluster-marker-${id}"><span>${countWord}</span></div>`,
      iconSize: L.point(40, 40),
    });
    return icon;
  };

  const spiderfyOnClick = (
    superCluster: Supercluster<PointProperties, PointProperties>,
    cluster: MarkerCluster,
    clusterId: number,
  ): void => {
    const markers = superCluster.getLeaves(clusterId, Infinity);

    map.fire('spiderfy');
    setSpiders(markers);
    setSelectedCluster(cluster);
    map.once('zoomend click spiderfy dragend', () => {
      setSpiders([]);
      setSelectedCluster(undefined);
    });
  };

  const handleClusterClick = useMemo(() => (
    cluster: MarkerCluster,
    clusterBound: L.LatLngBounds,
    superCluster?: Supercluster<PointProperties, PointProperties>,
  ) => {
    if (!(superCluster && cluster && cluster.id)) return;
    const clusterId = typeof cluster.id === 'string' ? parseInt(cluster.id, 2) : cluster.id;
    const expansionZoom = Math.min(
      superCluster.getClusterExpansionZoom(clusterId),
      MAP_MAX_ZOOM,
    );

    if (map.getZoom() < expansionZoom) {
      map.flyToBounds(clusterBound.pad(CLUSTER_BOUNDS_PADDING), {
        animate: true,
        duration: 0.5,
        maxZoom: expansionZoom,
      });

      map.once('zoomend moveend', () => {
        if (expansionZoom < MAP_MAX_ZOOM) return;
        spiderfyOnClick(superCluster, cluster, clusterId);
      });
      return;
    }

    spiderfyOnClick(superCluster, cluster, clusterId);
  }, [map]);

  // using bounds below in the useMemo dependency array is used purely as a trigger to recalculate
  const centerLong = useMemo(() => map.getCenter().lng, [currentBounds, map]);

  useEffect(() => {
    if (!(supercluster && thingId && zoom >= MAP_MAX_ZOOM)) return;
    clusters.forEach((cluster) => {
      if (isCluster(cluster)) {
        const leaves = supercluster.getLeaves(cluster.id as number, Infinity);
        const isSelectedEntityIncluded = leaves.some((item) => item.properties.markerThingId === thingId);
        if (!isSelectedEntityIncluded) return;
        spiderfyOnClick(supercluster, cluster, cluster.id as number);
        map.fire('markerLoaded');
        return;
      }
      if (cluster.properties.markerThingId === thingId) map.fire('markerLoaded');
    });
  }, [clusters, supercluster, thingId, zoom]);

  const boundsContainsPoint = (cBounds: BoundingBox, point: L.LatLng): boolean => {
    const { north, south, east, west } = cBounds;
    const { lat, lng } = point;

    // Normalize longitude to be within -180 to 180 range
    const normalizeLongitude = (lngValue: number): number => {
      while (lngValue < -180) lngValue += 360;
      while (lngValue > 180) lngValue -= 360;
      return lngValue;
    };

    // Check if longitude crosses the antemeridian
    const crossesAntemeridian = normalizeLongitude(west) > normalizeLongitude(east);

    // Adjust longitude for world copies
    const lngNormalized = crossesAntemeridian && lng < west ? lng + 360 : lng;

    const epsilon = 0.000001; // Small tolerance value for precision issues

    // Check if the point is within the latitude bounds
    const latInRange = lat >= south - epsilon && lat <= north + epsilon;

    // Check if the point is within the longitude bounds
    let lngInRange;
    if (crossesAntemeridian) {
      lngInRange = lngNormalized >= west - epsilon && lngNormalized <= east + 360 + epsilon;
    } else {
      lngInRange = lngNormalized >= west - epsilon && lngNormalized <= east + epsilon;
    }

    return latInRange && lngInRange;
  };

  const mapMarkers = useMemo<JSX.Element[]>(
    () => clusters.map((cluster, index) => {
      const [lng, lat] = cluster.geometry.coordinates;
      const position = new L.LatLng(lat, wrapLongIfNecessary(lng, centerLong));

      // check if point is inside the current map bounds before rendering
      if (currentBounds && !boundsContainsPoint(currentBounds, position)) return <></>;

      if (isCluster(cluster)) {
        const { point_count: pointCount, point_count_abbreviated: pointCountAbbr } = cluster.properties;

        return (
          <Box key={cluster.id}>
            <Marker
              eventHandlers={{
                click: () => {
                  const clusterBound = new L.LatLngBounds([[lat, wrapLongIfNecessary(lng, centerLong)]]);
                  handleClusterClick(cluster, clusterBound, supercluster);
                },
              }}
              icon={createClusterIcon(pointCount, pointCountAbbr as string, index)}
              position={position}
            />
            {selectedCluster && selectedCluster.id === cluster.id &&
              <SpiderMarkers
                cluster={selectedCluster}
                disableMarkerClick={disableMarkerClick}
                markers={spiders}
              />
            }
          </Box>
        );
      }

      const { markerThingId } = cluster.properties;

      return <ThingMarker key={markerThingId} disableMarkerClick={disableMarkerClick} fetchState={fetchState} position={position} thingId={markerThingId} template={template} />;
    }), [clusters, selectedCluster, spiders, supercluster, zoom, currentBounds]);

  if (!isThingsLayerSelected || !loaded) return null;

  return <>{mapMarkers}</>;
};

const getInitialBounds = (map: L.Map): Nullable<BoundingBox> => {
  const bounds = map.getBounds();
  if (!bounds.isValid()) return null;
  return boundsToBoundingBox(bounds);
};

const boundsToBoundingBox = (bounds: L.LatLngBounds): BoundingBox => {
  return {
    east: bounds.getEast(),
    north: bounds.getNorth(),
    south: bounds.getSouth(),
    west: bounds.getWest(),
  };
};
