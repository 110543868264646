/* eslint-disable react-hooks/exhaustive-deps */
import { Recipient, User, UserGroup } from '@eagle/core-data-types';
import { Button, Stack } from '@mui/material';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import validator from 'validator';
import { useAuthenticated } from '../../auth';
import { ErrorMessage, getBrowserLocale, getCanonicalTimeZone, LoadingButton, useBoolFlag } from '../../components';
import { T_ONE } from '../../constants';
import { useFetchAllCache, usePromise } from '../../hooks';
import { CacheDataTypes, Nullable } from '../../types';
import { filterDeleted, FILTER_OUT } from '../../util';
import { DefaultRadioLabel } from '../subscription/default-radio-label';
import { ContactMethodRadioButtons, NotificationRecipientState, RadioButtons, RecipientUnion } from '../subscription/subscription-detail.types';
import { generateRecipient } from '../subscription/subscription-detail.utils';
import { CreateSubscriptionNotificationRecipientView } from './create-subscription-notification-recipient-view';

interface Props {
  createInProgress: boolean;
  createSubscription: () => Promise<void>;
  isProfilePage: boolean;
  onActiveBack: () => void;
  updateNotificationRecipient: (recipient: Recipient) => void;
}

export const CreateSubscriptionNotificationRecipient: FC<Props> = ({ createInProgress, createSubscription, isProfilePage, onActiveBack, updateNotificationRecipient }) => {
  const { t } = useTranslation(['admin', 'common']);
  const { account, axios, user: currentUser } = useAuthenticated();
  const { enqueueSnackbar } = useSnackbar();
  const isSmsEnabled = useBoolFlag('admin-sms-subscription-feature');
  const [createButtonDisabled, setCreateButtonDisabled] = useState(true);
  const [recipientState, setRecipientState] = useState<NotificationRecipientState>({
    contactMethod: isSmsEnabled ? RadioButtons.SMS : RadioButtons.EMAIL,
    defaultOrCustomContact: RadioButtons.DEFAULT,
    notificationGroup: RadioButtons.INDIVIDUAL,
    selectedRecipient: isProfilePage ? currentUser._id : null,
    user: RadioButtons.USER,
  });

  const [contactDetail, setContactDetail] = useState<Nullable<string>>(null);
  const [channelDetailHelper, setChannelDetailHelper] = useState('');
  const [isChannelDetailInvalid, setIsChannelDetailInvalid] = useState(false);
  const [selectedLocale, setSelectedLocale] = useState<string>(getBrowserLocale());
  const [selectedTimeZone, setSelectedTimeZone] = useState<string>(getCanonicalTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone));

  const [users, usersError, usersState] = usePromise<User[]>(
    async () => {
      const results = await axios.get<User[]>('/api/v1/admin/user', {
        params: {
          filter: { accountId: account._id, ...FILTER_OUT.deleted },
        },
      });
      return results.data.sort((a, b) => a.display.localeCompare(b.display));
    },
    [account],
  );

  const userGroupsCache = useFetchAllCache(CacheDataTypes.USER_GROUP);
  const [userGroups, userGroupsError, userGroupsState] = usePromise(
    async () => {
      const data = await userGroupsCache.all<UserGroup>();
      const filtered = data.filter((obj) => {
        return obj.accountBinding.some((id: string) => id === account._id);
      });
      return filterDeleted(filtered);
    },
    [userGroupsCache],
  );

  const recipientsPending = usersState === 'pending' || userGroupsState === 'pending';
  const recipients: RecipientUnion[] = (recipientState.notificationGroup === RadioButtons.INDIVIDUAL ? users : userGroups) ?? [];
  const isContactOptionsVisible = !(recipientState.user === RadioButtons.USER && !recipientState.selectedRecipient);
  const isContactDetailFieldVisible = recipientState.notificationGroup !== RadioButtons.GROUP || recipientState.user === RadioButtons.OTHER;

  const handleSelectChange = (propertyPath: keyof NotificationRecipientState, value: string): void => {
    setRecipientState((prev) => {
      return {
        ...prev,
        [propertyPath]: value,
      };
    });
  };

  const handleEmailFieldChange = (value: string): Promise<void> => {
    const isValueValid = validator.isEmail(value);
    if (isValueValid) {
      setChannelDetailHelper('');
      setIsChannelDetailInvalid(false);
      setContactDetail(value);
      return Promise.resolve();
    }
    setChannelDetailHelper(t('common:common.hint.email'));
    setIsChannelDetailInvalid(true);
    setContactDetail(value);
    return Promise.resolve();
  };

  const getCustomLabel = (contactMethod: ContactMethodRadioButtons): string => {
    switch (contactMethod) {
      case RadioButtons.EMAIL: return t('admin:page.create-subscription.notification-recipient.labels.use-different-email');
      case RadioButtons.SMS: return t('admin:page.create-subscription.notification-recipient.labels.use-different-number');
    }
  };

  const userOptions = [
    {
      label: t('common:terms.user', { count: T_ONE }),
      value: RadioButtons.USER,
    },
    {
      label: t('common:terms.other'),
      value: RadioButtons.OTHER,
    },
  ];

  const notificationGroupOptions = [
    {
      label: t('admin:page.create-subscription.notification-recipient.labels.individual'),
      value: RadioButtons.INDIVIDUAL,
    },
    {
      label: t('admin:page.create-subscription.notification-recipient.labels.group'),
      value: RadioButtons.GROUP,
    },
  ];

  const contactOptions = [
    {
      label: t('admin:page.create-user.basic-info.email.label'),
      value: RadioButtons.EMAIL,
    },
  ];
  if (isSmsEnabled) {
    contactOptions.unshift({
      label: t('admin:page.create-subscription.notification-recipient.labels.sms'),
      value: RadioButtons.SMS,
    });
  }

  const defaultOptions = [
    {
      label: <DefaultRadioLabel contactMethod={recipientState.contactMethod} isProfilePage={isProfilePage} userId={recipientState.selectedRecipient ?? ''} />,
      value: RadioButtons.DEFAULT,
    },
    {
      label: getCustomLabel(recipientState.contactMethod),
      value: RadioButtons.CUSTOM,
    },
  ];

  useEffect(() => {
    const recipient = generateRecipient(contactDetail, selectedLocale, selectedTimeZone, recipientState);
    if (recipient) updateNotificationRecipient(recipient);
  }, [recipientState, selectedLocale, selectedTimeZone, contactDetail]);

  const isInvalid = (): boolean => {
    const { defaultOrCustomContact, notificationGroup, selectedRecipient, user } = recipientState;
    const hasValidContact = validator.isEmail(contactDetail ?? '') || validator.isMobilePhone(contactDetail ?? '') && isValidPhoneNumber(contactDetail ?? '');

    if (user === RadioButtons.USER) {
      switch (notificationGroup) {
        case RadioButtons.INDIVIDUAL: return !selectedRecipient || (defaultOrCustomContact !== RadioButtons.DEFAULT && !hasValidContact);
        case RadioButtons.GROUP: return !selectedRecipient;
      }
    }

    return !hasValidContact;
  };

  useEffect(() => setCreateButtonDisabled(isInvalid()), [recipientState, contactDetail]);

  if (usersError) enqueueSnackbar(<ErrorMessage error={usersError} />, { variant: 'error' });
  if (userGroupsError) enqueueSnackbar(<ErrorMessage error={userGroupsError} />, { variant: 'error' });

  return (
    <Stack spacing={2}>
      <CreateSubscriptionNotificationRecipientView
        channelDetailHelper={channelDetailHelper}
        contactDetail={contactDetail}
        contactOptions={contactOptions}
        currentUser={currentUser}
        defaultOptions={defaultOptions}
        fieldDisabled={createInProgress}
        handleEmailFieldChange={handleEmailFieldChange}
        handleSelectChange={handleSelectChange}
        isChannelDetailInvalid={isChannelDetailInvalid}
        isContactDetailFieldVisible={isContactDetailFieldVisible}
        isContactOptionsVisible={isContactOptionsVisible}
        isProfilePage={isProfilePage}
        notificationGroupOptions={notificationGroupOptions}
        recipients={recipients}
        recipientsError={!!(usersError || userGroupsError)}
        recipientsPending={recipientsPending}
        recipientState={recipientState}
        selectedLocale={selectedLocale}
        selectedTimezone={selectedTimeZone}
        setChannelDetailHelper={setChannelDetailHelper}
        setContactDetail={setContactDetail}
        setIsChannelDetailInvalid={setIsChannelDetailInvalid}
        setRecipientState={setRecipientState}
        setSelectedLocale={setSelectedLocale}
        setSelectedTimezone={setSelectedTimeZone}
        userOptions={userOptions}
      />

      <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end', my: 1 }}>
        <Button disabled={createInProgress} variant="outlined" onClick={onActiveBack} data-testid="button-back">{t('common:common.action.back')}</Button>
        <LoadingButton
          data-testid="create-button"
          disabled={createButtonDisabled}
          loadingCaption={t('common:common.hint.saving')}
          task={createSubscription}
          variant='contained'
        >
          {t('common:common.action.create-entity', { entity: t('common:terms.subscription', { count: T_ONE }) })}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};
